/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          $('.featured-comments-slider').flexslider({
              animation: 'slide',
              selector: '.featured-comments-slider__slides > .featured-comments-slider__slide',
              directionNav: false,
              itemMargin: 3, // Fixes slight overflow from quote marks
              controlsContainer: '.featured-comments-slider__controls',
              slideshowSpeed: 14000
          });

          $('.raty-rating__container--housing-average').each(function() {
              $(this).raty({
                  starOn: 'icon-star active',
                  starOff: 'icon-star',
                  starHalf: 'icon-star-half',
                  readOnly: true,
                  score: $(this).siblings('.raty-rating__score').text(),
              });
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

    /**
     * Modal functions
     */
    $('#star-rating img').hover(function() {
        $('#star-rating').tooltip('hide');
    });

    $('#rating-submit img').on("click", function() {
        $('#star-rating').tooltip('hide');
    });

    $('#modal-done').on("click", function() {

        $('#overall-rating h2').text('How you rated us');
        $('#star-rating').raty('readOnly', true);

        $('#rating-submit').hide();
        $('.rating-footer').show();

    });

    ///**
    // * Cookie handling
    // */
    //
    //showCompleteSurveys();//Sets complete classes from session cookie
    //
    ///**
    // * Save form ID in cookie value, append if other values present
    // * @param name - form ID
    // */
    //function saveSurvey(name) {
    //
    //
    //    var cookie = Cookies.get('whg-survey');
    //    var items = cookie ? cookie.split(/,/) : new Array();
    //    items.push(name);
    //    Cookies.set('whg-survey', items.join(','), {  expires: 1 });
    //
    //}
    //
    ///**
    // * Get completed forms from Cookie, iterate over and add complete classes to survey divs
    // */
    //function showCompleteSurveys() {
    //    var cookie = Cookies.get('whg-survey');
    //    var items = cookie ? cookie.split(/,/) : new Array();
    //
    //    $.each(items,function(i){;
    //        $('.survey-block #' + items[i]).addClass('light-grey done');
    //    });
    //
    //}

    /**
     * Smilies - satisfaction field
     */

    //Use document 'click' evetns to capture click events after AJAX validation
    $( document ).on( "click", ".smilies img", function() {
        var val = parseInt($( this ).data('val'));

        $(this).siblings().removeClass('selected');
        $(this).addClass('selected');
        //highlightFaces($(this), val, 'selected');

        //Save value in input
        $( this ).parent().find('.smiley-val').val(val);
    });

    /*
    $('.smilies img').hover(function() {
        var val = parseInt($( this ).data('val'));
        highlightFaces($( this ), val, 'fade');
    });
    */

    $('.smilies').mouseleave(function() {
        $('.smilies img').removeClass('fade');
    });

    function highlightFaces(elem, val, attr) {

        elem.siblings().removeClass(attr);

        //Use parent then children img to get siblings in correct order
        elem.parent().children('img').each(function( index ) {
            //console.log("itr elem=" + elem.data('val') + " val=" + val);
            if ($( this ).data('val') <= val) {
                $( this ).addClass(attr);
            }
        });
    }

})(jQuery); // Fully reference jQuery after this point.
